<template>
  <div class="pd10 print-pane">
    <div class="print-header">
      <h3 class="center">{{companyName}}</h3>
      <p class="center">
        <span>天气：{{tempInfo.weather}}</span>
        <span>最高温：{{tempInfo.maxWeatherTemp}}</span>
        <span>最低温：{{tempInfo.minWeatherTemp}}</span>
        <span>风向：{{tempInfo.windDirection}}</span>
        <span>风速：{{tempInfo.windPower}}</span>
      </p>
      <p class="center">
        <span>{{tempInfo.houseName}}</span>
        <span>{{tempInfo.testingTime}}</span>
        <span v-if="printConfig.printStorehouse">仓温：{{tempInfo.storehouseTemp}}℃</span>
        <span v-if="printConfig.printStorehouse">仓湿：{{tempInfo.storehouseDampness}}%</span>
        <span v-if="printConfig.printExternal">气温：{{tempInfo.externalTemp}}℃</span>
        <span v-if="printConfig.printExternal">气湿：{{tempInfo.externalDampness}}%</span>
      </p>
    </div>
    <PaneData ref="paneData" :tempInfo="tempInfo"  :printArchives="printConfig.printArchives"/>
  </div>
</template>

<script>
  import printJS from 'print-js'
  export default {
    components: {
      PaneData: () => import('@/components/PaneData')
    },
    data() {
      return {
        tempInfo: {},
        companyName: "",
        compareId: '',
        tempId: '',
          printConfig: {}
      }
    },
    mounted() {
      this.compareId = this.$route.query.compareId
      this.tempId = this.$route.query.id
      this.cfParamSee();
    },
    methods: {
      //获取打印数据
      cfParamSee() {
        this.$axios.Get(this.$api.cfParamSee, {}).then(res => {
          this.printConfig = res.data
          this.getPrintData()
        }).catch(() => {
          this.getPrintData()
        })
      },
      //获取打印数据
      async getPrintData() {
        let res = await this.$axios.Get(this.$api.configGrain, {})
        this.companyName = res.data
        let param = {}
        if (this.compareId) param['compareID'] = this.compareId
        this.$axios.Get(this.$api.testingTempByIdTable + this.tempId, param).then(res => {
          this.tempInfo = res.data
          this.$nextTick().then(() => {
            setTimeout(() => {
              this.printHTML(this.$refs.paneData.$el)
            }, 500)
          })
        })
      },
      //打印
      printHTML(el) {
        const els = el.querySelectorAll(".el-table")
        const div = document.createElement('div')
        const printDOMID = 'printDOMElement'
        div.id = printDOMID
        let printHeader = document.querySelector(".print-header")
        div.appendChild(printHeader.cloneNode(printHeader))
        els.forEach(item => {
          let item_ = item.cloneNode(item)
          let tr = item_.querySelector(".el-table__header-wrapper tr");
          let tbody = item_.querySelector(".el-table__body-wrapper tbody");
          if (tr) {
            tbody.insertBefore(tr.cloneNode(tr), tbody.querySelectorAll("tr")[0])
            tr.remove()
          }
          div.appendChild(item_)
        })
        // 将新的 DIV 添加到页面 打印后再删掉
        document.querySelector('body').appendChild(div)

        printJS({
          documentTitle: "平面打印",
          printable: printDOMID,
          type: 'html',
          scanStyles: false,
          style: (this.tempInfo.type !== 0 ? ' @page{size: A4 landscape;}' : '') +' table {width: 100%!important;} .table th > .cell { padding-left:0!important; padding-right:0!important;} .table .cell { padding-left:0!important; padding-right:0!important; } table tr td,th { border-collapse: collapse;padding: 15px;border:1px #EBEEF5 solid;text-align:center; } .center{text-align:center;} '
        })
        div.remove()
      }
    }
  }
</script>
<style scoped lang="less">
  // @page {
  // size: A4 portrait;
  /*portrait： 纵向打印,  landscape: 横向*/
  // }

  .print-header {

    p+p {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }

    span+span {
      margin-left: 15px;
    }
  }
</style>
